import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import app from './app'
import localforage from 'localforage'

// Mixins
import langMixin from './mixins/lang'
import navMixin from './mixins/navigate'
import resourceMixin from './mixins/resource'
import helpersMixin from './mixins/helpers'


// Global SCSS
import './scss/style.scss'

// Create App
let vueApp = createApp(App)

// Offline storage
localforage.config({
    driver      : localforage.INDEXEDDB,
    name        : 'astra',
    version     : 1.0,
});

// Components
vueApp.component('background', require('./components/Background.vue').default)
vueApp.component('c-select', require('./components/Select.vue').default)
vueApp.component('c-button', require('./components/Button.vue').default)
vueApp.component('c-square-button', require('./components/ButtonSquare.vue').default)
vueApp.component('icon', require('./components/Icon.vue').default)
vueApp.component('top-bar', require('./components/TopBar.vue').default)
vueApp.component('progress-ring', require('./components/ProgressRing.vue').default)
vueApp.component('section-title', require('./components/SectionTitle.vue').default)
vueApp.component('page', require('./components/Page.vue').default)
vueApp.component('page-aside', require('./components/PageAside.vue').default)
vueApp.component('post', require('./components/Post.vue').default)
vueApp.component('language-picker', require('./components/LanguagePicker.vue').default)
vueApp.component('menu-button', require('./components/MenuButton.vue').default)
vueApp.component('drawer-menu', require('./components/DrawerMenu.vue').default)
vueApp.component('view-header', require('./components/ViewHeader.vue').default)
vueApp.component('placeholder', require('./components/Placeholder.vue').default)
vueApp.component('resource-placeholder', require('./components/ResourcePlaceholder.vue').default)
vueApp.component('spiner', require('./components/Spiner.vue').default)
vueApp.component('progress-bar', require('./components/ProgressBar.vue').default)
vueApp.component('post-content', require('./components/PostContent.vue').default)
vueApp.component('post-content-strapi', require('./components/PostContentStrapi.vue').default)

// Setup
vueApp.use(store)
    .use(router)
    .mixin(langMixin)
    .mixin(navMixin)
    .mixin(resourceMixin)
    .mixin(helpersMixin)


app.initialize().then( async () => {

    if(!app.getConfig('notFirstTime')){

        await router.replace({
            path: '/language',
            query: {
                redirect: '/welcome'
            }
        })

        app.setConfig('notFirstTime', true)

    }

    vueApp.mount('#app')

} ).catch( (e) => {

    console.log("Failed to initialize app: ", e)

} )



