<template>
    <background>
        <div class="flex-layout">
        <div class="view">
            <div class="welcomePage">
                <carousel :items-to-show="1" @update:modelValue="slideNum = $event" ref="slider">
                    <slide v-for="slide in 4" :key="slide">
                        <div class="slider-item">
                           <div class="padding">
                               <img class="clipart" :src="require('../assets/img/intro-' + slide + '.png')">
                               <div class="heading">{{ lang('views.intro.' + slide + '.title') }}</div>
                               <p>{{ lang('views.intro.' + slide + '.description') }}</p>
                           </div>
                        </div>
                    </slide>
                </carousel>
                <div class="button">
                    <c-button @click="goTo(slideNum+1)" v-if="slideNum != 3">{{ lang('views.intro.button.next') }}
                    </c-button>
                    <div class="buttons" v-else>
                        <c-button @click="goToSurvey">{{ lang('views.intro.button.survey') }}
                        </c-button>
                        <c-button :ghost="true" @click="$router.push('/')">{{ lang('views.intro.button.survey.skip') }}
                        </c-button>
                    </div>
                </div>
                <div class="dots">
                    <button @click="goTo(0)" :class="{active: slideNum == '0'}"></button>
                    <button @click="goTo(1)" :class="{active: slideNum == '1'}"></button>
                    <button @click="goTo(2)" :class="{active: slideNum == '2'}"></button>
                    <button @click="goTo(3)" :class="{active: slideNum == '3'}"></button>
                </div>
            </div>
        </div>
        </div>
    </background>
</template>


<script>
//@include glass-style();
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';

export default {
    name: 'App',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    methods: {

        goTo(n) {
            this.slideNum = n
            this.$refs.slider.slideTo(this.slideNum)
        },
        goToSurvey(){

            this.$router.push('/')
            // this.$router.push('/survey-welcome')

        }
    },
    data() {
        return {
            slideNum: 0
        }
    }
};

</script>


<style lang="scss" scoped>


.view{
    justify-content: center;
    align-items: center;
    display: flex;
}

.welcomePage {

    .carousel {



        .slider-item {
            height: 100%;
            width: 90%;
            max-width: 400px;

            img {
                width: 100%;
                height: 250px;
                object-fit: contain;
                object-position: center;
                border-radius: 50%;
                display: flex;
                margin-bottom: 20px;
            }

        }

        .heading {
            width: 80%;
            font-size: 1.2em;
            font-weight: 600;
            margin: auto;
            margin-bottom: 20px;
            line-height: 20px;

        }

        p {

            font-size: 0.9em;
            text-align: center;
            line-height: 20px;
            opacity: 0.5;
            height: 100px;

        }
    }

    .dots {

        text-align: center;
        justify-content: center;

        button {
            background-color: white;
            opacity: 0.3;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            text-decoration: none;
            border: none;
            margin-right: 5px;
            margin-left: 5px;


            &.active {
                opacity: 1;

            }
        }
    }

    .buttons{

    }
    .button {
        margin: 0 7px;
        align-items: center;
        justify-content: center;
        display: flex;
        padding-top: 0px;
        padding-bottom: 10px;

        .button[data-v-5c9fcf72] {
            padding-bottom: 15px;
        }


    }

}

.skip{
    background: none !important;
}


</style>
