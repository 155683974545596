<template>
    <background>
        <div class="flex-layout">
            <view-header :title="lang('menu.sos')"></view-header>
            <div class="view p-lr">
                <p class="text-container">{{ lang('views.sos.description') }}</p>
                <div @click="navigate('/report')" class="item">
                    <c-square-button icon="send"></c-square-button>
                    <div class="contact">
                        <p>{{ lang('views.sos.report-problem.title') }}</p>
                        <span>{{ lang('views.sos.report-problem.message') }}</span>
                    </div>
                </div>
                <hr class="separator">
                <a :href="'tel:+381 11 785 0000'" target="_blank" class="item">
                    <c-square-button icon="phone"></c-square-button>
                    <div class="contact">
                        <p>+381 11 785 0000</p>
                        <span>{{ lang('views.sos.phone.1.message') }}</span>
                    </div>
                </a>
                <a :href="'tel:0800 101 201'" target="_blank" class="item">
                    <c-square-button icon="phone"></c-square-button>
                    <div class="contact">
                        <p>0800 101 201</p>
                        <span>{{ lang('views.sos.phone.2.message') }}</span>
                    </div>
                </a>
                <hr class="separator">
                <a href="http://facebook.com" target="_blank" class="item">
                    <c-square-button icon="facebook"></c-square-button>
                    <div class="contact">
                        <p>{{ lang('views.sos.fb.title') }}</p>
                        <span>{{ lang('views.sos.fb.message') }}</span>
                    </div>
                </a>
                <a href="mailto:test@test.com" target="_blank" class="item">
                    <c-square-button icon="mail"></c-square-button>
                    <div class="contact">
                        <p>{{ lang('views.sos.mail.title') }}</p>
                        <span>{{ lang('views.sos.mail.message') }}</span>
                    </div>
                </a>
            </div>
        </div>
    </background>
</template>


<script>

import ButtonSquare from "../components/ButtonSquare.vue"

export default {}


</script>


<style lang="scss" scoped>

@import "../scss/includes.scss";

.view {
    padding-bottom: 40px;
}


.separator {
    margin-bottom: 15px;
    margin-top: 15px;
}

.item {
    text-decoration: none;
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    outline: none !important;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    .contact {
        flex: 1;
        margin-left: 15px;

        p {

            font-weight: 600;
            font-size: 1.3em;
            justify-content: center;
            align-items: center;

        }

        span {
            font-size: 0.8em;
            line-height: 1.1em;
            margin-top: 3px;
            display: block;
            opacity: 0.5;
        }
    }


}


</style>