import lang from '../lang'
import app from '../app'

export default {

    methods: {

        /**
         *
         * @param key
         * @returns {*}
         */
        lang(key){


            let activeLang = app.getConfig('lang');

            for( let i = 0; i < lang.availableLanguages.length; i++ ){

                if(activeLang === lang.availableLanguages[i].key){

                    if(lang.availableLanguages[i].data[key])

                        return lang.availableLanguages[i].data[key]

                }

            }

            return '-----';

        },


    }



}