import {createStore} from 'vuex'

export default createStore({
    state: {

        // Default configuration
        config : {
            lang: 'en'
        },

        // Menu
        menuVisible: false,

        // Resources
        resources: {},

    },

    getters: {

        /**
         *
         * @param state
         * @returns {state.config|{lang}}
         */
        config: state => {
            return state.config
        },

        /**
         *
         * @param state
         * @returns {any}
         */
        survey: state => {
            return state.resources.survey
        },

        /**
         *
         * @param state
         */
        resources: state => {
            return state.resources
        }

    },
    mutations: {

        /**
         * @param state
         * @param config
         */
        setConfig(state, config){
            state.config = config
        },

        /**
         *
         * @param state
         * @param resources
         */
        setResources(state, resources){
            state.resources = resources
        },

        /**
         * @param state
         * @param visible
         */
        setMenuVisible(state, visible){
            state.menuVisible = visible
        },

        /**
         * @param state
         */
        toggleMenu(state){
            state.menuVisible = !state.menuVisible
        },

    },
    actions: {


    },
    modules: {}
})
