<template>
    <div id="main">
        <router-view v-slot="{ Component, route }">

            <transition :name="route.meta.transitionName">
                <keep-alive :include="keepAliveInclude">
                    <component :is="Component" :key="$route.path"/>
                </keep-alive>
            </transition>

        </router-view>
        <drawer-menu></drawer-menu>
    </div>
</template>

<script>

    export default {
        data(){
            return {
                keepAliveInclude: []
            }
        },
        components: {

        }
    }

</script>

