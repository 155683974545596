<template>
    <background>

        <div class="flex-layout ">

            <view-header
                :disable-language-picker="true"
                :disable-menu-drawer="true"
                :title="lang('views.post.title')"
            ></view-header>

            <div class="view p-lr post-container" v-if="post">

                <img v-if='wordpressPostHasFeaturedImage(post)' :src="wordpressPostFeaturedImage(post, 'medium')"  class="featured">
                <h1>{{decodeHTMLEntities(post.title.rendered)}}</h1>
                <post-content  class='content' :content="post.content.rendered">
                </post-content>

            </div>

            <div class="view p-lr" v-else>
                <placeholder
                              icon="alert"
                              title="No post with requested parameters" >
                </placeholder>

            </div>



        </div>

    </background>
</template>

<script>

    export default {

        data(){
            return{
                post: null,
            }
        },

        beforeMount(){

            let posts = this.wpLocalizedResource('posts', this.$route.query.lang);
            let post;

            posts.forEach((p) => {
                if(p.id == this.$route.query.post){
                    post = p;
                }
            })

            if(this.$route.query.example)
                this.post = posts[0];

            this.post = post;

        }

    }



</script>

<style scoped>

    h1{
        line-height: 1.4em;
        text-align: center;
    }

    img.featured{
        margin-top: 50px;
        width: 100%;
        height: calc(50vw * 1);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        margin-bottom: 30px;

    }

    .post-container{
        padding-bottom: 30px;
    }

</style>
