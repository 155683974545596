export default {

    "views.language.title": "Select language",
    "views.language.confirm": "Confirm",

    // Intro
    "views.intro.1.title": "Welcome to Astra",
    "views.intro.2.title": "Fight against human trafficking",
    "views.intro.3.title": "Learn more about trafficking",
    "views.intro.4.title": "Help us, fill survey",
    "views.intro.1.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "views.intro.2.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "views.intro.3.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "views.intro.4.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "views.intro.button.next": "Next",
    "views.intro.button.survey": "Survey",
    "views.intro.button.survey.skip": "Skip",
    "views.intro.button.start.survey": "Start survey",
    "views.intro.button.back.survey": "Go Back",
    "views.intro.button.start.game": "Start game",

    // SOS
    "views.sos.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    "views.sos.report-problem.title": "Report a problem",
    "views.sos.report-problem.message": "Fill in form to anonymously report problem",
    "views.sos.phone.1.message": "For human-trafficking victims",
    "views.sos.phone.2.message": "For human-trafficking victims",
    "views.sos.fb.title": "Facebook",
    "views.sos.fb.message": "Official facebook page",
    "views.sos.mail.title": "E-Mail",
    "views.sos.mail.message": "Contact us via E-mail",

    //Country residence
    "views.eol.country.title": "Select country",
    "views.eol.country.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry",


    //Exploitation
    "views.exploitation.description":"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",

    "views.exploitation.general.title": "General information",
    "views.exploitation.general.desc": "General information about Exploitation",
    "views.exploitation.domestic.title": "If you are in foreign country",
    "views.exploitation.domestic.desc": "For Serbian residents",
    "views.exploitation.foreign.title": "If you are foreigner in Serbia",
    "views.exploitation.foreign.desc": "For non-Serbian residents",

    // Home
    "views.home.quiz.title": "Quiz game",
    "views.home.quiz.description": "Learn more about anti-trafficking through interactive quiz game",
    "views.home.quiz.play": "Play",
    "views.home.news-activities-title": "News & Activities",
    "views.home.more": "More news",

    // Quiz
    "views.quiz.title": "Quiz",
    "views.quiz.description": "Learn more about anti-trafficking through interactive quiz game",
    "views.quiz.play": "Start game",
    'views.quiz.stats.question': 'Question',
    'views.quiz.stats.score': 'Score',
    'views.quiz.stats.time': 'Time',
    'views.quiz.end.title': 'Lorem Ipsum',
    'views.quiz.end.description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    'views.quiz.end.correct-answers': 'Correct answers',
    'views.quiz.end.incorrect-answers': 'Incorrect answers',
    'views.quiz.end.question-summary': 'Questions',
    'views.quiz.timeout': 'Time is up',
    'views.quiz.action.next': 'Next',
    'views.quiz.action.ok': 'Ok',
    'views.quiz.action.skip': 'Skip',
    'views.quiz.action.confirm': 'Confirm',
    'views.quiz.exit.title': 'Are you sure you want to exit?',
    'views.quiz.exit.desc': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',

    // Anti trafficking
    "views.anti-trafficking.title": "Anti trafficking",

    // Report problem
    "views.report-problem.intro.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    "views.report-problem.form.d.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    "views.report-problem.form.a.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    "views.report-problem.action.data": "Report with personal data",
    "views.report-problem.action.anonymous": "Report anonymously",
    "views.report-problem.end.placeholder.title": "Thank you",
    "views.report-problem.end.placeholder.message": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "views.report-problem.end.placeholder.back": "Go back",
    'views.report-problem.form.label.name': "Full name",
    'views.report-problem.form.label.phone': "Phone",
    'views.report-problem.form.label.mail': "Mail",
    'views.report-problem.form.label.title': "Title",
    'views.report-problem.form.label.message': "Message",
    'views.report-problem.form.submit': "Submit form",
    'views.report-problem.validation.title': 'Please check fields',
    'views.report-problem.validation.desc': 'Some fields are empty or in wrong format. Please fill in all required fields.',
    'views.report-problem.client.title': 'Client error',
    'views.report-problem.client.desc': 'No network',
    'views.report-problem.server.title': 'Server error',
    'views.report-problem.server.desc': 'Error on the web server',

    // Survey
    "views.survey.title": "Survey",
    "views.survey.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "views.survey.start": "Start survey",
    'views.survey.exit.title': 'Are you sure you want to exit?',
    'views.survey.exit.desc': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    "views.survey-welcome.title": "Survey Welcome",
    "views.survey-welcome.placeholder.title": "Take a survey",
    "views.survey.placeholder.content": "Maecenas sit amet sollicitudin dolor, quis fringilla tortor, quis fringilla tortor.",
    "views.survey.placeholder.button.content": "Go Forward",
    "views.survey.button.next": "Next",
    "views.survey.button.previous": "Prev",
    "views.survey.end-screen.title": "Thank you",
    "views.survey.end-screen.content": "Maecenas sit amet sollicitudin dolor, quis fringilla tortor.",
    "views.survey.end-screen.button.content": "Go Back",

    // Post
    'views.post.title' : 'News',

    // Resource placeholder
    'placeholder.network.title': 'Network error',
    'placeholder.network.message': 'There is no data available. Please check your internet connection and try again.',
    'placeholder.network.action': 'Try again',

    // Menu
    "menu.home": "Home",
    "menu.anti-trafficking": "Anti trafficking",
    "menu.eol": "Exploitation of labour",
    "menu.sos": "SOS",
    "menu.quiz": "Quiz game",
    "menu.survey": "Survey",
    "menu.report-problem": "Report problem",

    // Misc
    "cancel": "Cancel",
    "confirm": "Confirm",
    "ok": "OK",
    "yes": "Yes",
    "no": "No",
    "translate": "Translate",
    "available-translations": "Available translations",
    "auto-translate": "Auto-translate",

}