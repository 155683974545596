<template>
    <background>

        <div class="flex-layout">

            <top-bar id="top-bar" :class="{collapse: collapse}">
                <div class="view p-lr p-tb">

                    <div class="top-menu-left-btn">
                        <language-picker></language-picker>
                    </div>

                    <div class="top-menu-right-btn">
                        <menu-button></menu-button>
                    </div>

                    <img id="logo" src="../assets/img/logo.svg" alt="">

                    <hr class="separator">

                    <div class="social-networks">
                        <div class="row">
                            <div class="col col-4">
                                <a :href="settings('fbPageUrl')" target="_blank">
                                    <button class="ripple">
                                        <icon icon="facebook"></icon>
                                    </button>
                                </a>
                            </div>
                            <div class="col col-4">
                                <a :href="settings('ytPageUrl')" target="_blank">
                                    <button class="ripple">
                                        <icon icon="youtube"></icon>
                                    </button>
                                </a>
                            </div>
                            <div class="col col-4">
                                <a :href="settings('instaPageUrl')" target="_blank">
                                    <button class="ripple">
                                        <icon icon="instagram"></icon>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>

                    <hr class="separator">

                    <div class="quiz-stats">
                        <progress-ring class="progress-ring" :size="90" :value="$store.getters.config.score || 0"
                                       :total="totalPoints"/>
                        <div class="info">
                            <div class="title">{{ lang('views.home.quiz.title') }}</div>
                            <div class="description">{{ lang('views.home.quiz.description') }}</div>
                        </div>
                    </div>

                    <div class="quiz-play" @click="navigate('/quiz-welcome')">
                        <c-button icon="play">{{ lang('views.home.quiz.play') }}</c-button>
                    </div>

                </div>
            </top-bar>

            <div class="view p-lr main" ref="main">

                <icon class='pull-up' icon="chevrons-up"></icon>

                <div class="row main-menu">
                    <div class="col col-4">
                        <c-square-button icon="astra" @click="navigate('/anti-trafficking')">
                            {{ lang("menu.anti-trafficking") }}
                        </c-square-button>
                    </div>
                    <div class="col col-4">
                        <c-square-button icon="users" @click="navigate('/eol')">
                            {{ lang("menu.eol") }}
                        </c-square-button>
                    </div>
                    <div class="col col-4">
                        <c-square-button icon="phone" @click="navigate('/sos')">
                            {{ lang("menu.sos") }}
                        </c-square-button>
                    </div>
                </div>


                <section-title icon="info">{{ lang("views.home.news-activities-title") }}</section-title>

                <div class="row posts">

                    <resource-placeholder :resource="wpLocalizedResourceName('posts')">

                        <div class="items">

                            <div class="col col-12 col-md-6" v-for="post in posts" :key="post.id">
                                <router-link :to="wpPostResourceLink(post)">
                                    <post
                                        :image-url="wordpressPostFeaturedImage(post, 'medium')"
                                        :title="decodeHTMLEntities(post.title.rendered)"
                                        :meta="formatDate(post.date)">
                                    </post>
                                </router-link>
                            </div>

                            <div class="more-news">
                                <a :href="settings('newsPageUrl')" target="_blank">
                                    <c-button>
                                        {{lang('views.home.more')}}
                                    </c-button>
                                </a>
                            </div>

                        </div>

                    </resource-placeholder>

                </div>


            </div>

        </div>

    </background>
</template>

<script>

import helpers from '../helpers'
import settings from '../settings'
import app from "../app";


export default {
    name: 'Home',

    data() {
        return {
            progressValue: 0,
            collapse: false,
        }
    },

    computed: {


        posts() {

            return this.wpLocalizedResource('posts')

        },

        totalPoints() {

            return settings.questionsInQuiz * settings.questionPoint

        },


    },

    methods: {

        settings(key){
            return settings[key]
        },

        async loadResource() {

            this.loading = true;

            await app.resourceManager.loadResourceRemote('posts');

            this.loading = false;

        }

    },

    beforeUnmount() {

        this.$refs.main.removeEventListener('scroll', this.onScroll);

    },

    mounted() {

        this.onScroll = (e) => {
            this.collapse = e.target.scrollTop > 150
        }

        this.$refs.main.addEventListener('scroll', this.onScroll)

    }

}

</script>

<style lang="scss" scoped>

@import "../scss/includes.scss";

#top-bar{
    position: absolute;
    transition: all 0.4s ease-in-out;
    left: 0;
    width: 100%;

    &.collapse{
        transform: translate3d(0, -400px, 0);
    }

}

.pull-up{
    position: absolute;
    left: 0;
    right: 0;
    font-size: 40px;
    opacity: 0.5;
    margin: 0 auto;
    margin-top: -70px;
}

#logo {
    width: 120px;
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
}

.view.main{
    padding-top: 365px !important;
}

.posts{
    min-height: 250px;
}

.social-networks {

    padding: 0 12%;

    .col {

    }

    button {
        outline: none;
        border: none;
        background: none;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        margin: 0px auto;
        color: white;
    }

}

.quiz-stats {

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .progress-ring {
        font-size: 0.85em;
    }

    .info {
        margin-left: 10px
    }

    .title {
        font-size: 1.3em;
        font-weight: 500;
    }

    .description {
        margin-top: 10px;
        line-height: 1.4em;
        font-size: 0.80em;
        opacity: 0.7;
    }
}

.quiz-play {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.main-menu {
    margin: 30px 0;
}

.posts .col {

    margin-bottom: 20px;
}

.more-news {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
}


</style>