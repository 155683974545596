import lang from '../lang'
import app from '../app'

export default {

    methods: {

        /**
         * Navigate function
         * @returns {*}
         */
        navigate(path, query){

            setTimeout( () => {

                this.$router.push({
                    path: path,
                    query: query,
                })

            }, 0 )

        },

        /**
         *
         * @param post
         * @param lang
         * @returns {{to: string, params: {post, lang: (null|Promise)}}}
         */
        wpPostResourceLink(post, lang = null){

            return {
                path: '/post',
                query: {
                    lang: (lang ? lang : app.getConfig('lang')),
                    post: post.id
                }
            }

        },


    }



}