import store from './store'
import settings from './settings'
import lang from './lang'
import ResourceManager from './ResourceManager'
import HTTPManager from './HTTPManager'
import localforage from 'localforage';

export default {

    httpManager: new HTTPManager(),
    resourceManager: new ResourceManager(store),

    /**
     * Register resources
     */
    registerResources() {

        // Define resources
        for (let i = 0; i < lang.availableLanguages.length; i++) {
            this.resourceManager.setupResource('posts:' + lang.availableLanguages[i].key, 'https://astra.rs/wp-json/wp/v2/posts?_embed&lang=' + lang.availableLanguages[i].key)
        }

        this.resourceManager.setupResource('quizAndSurvey', settings.serverBaseUri + '/api/quiz-and-survey',  {
            params: {
                populate: [
                    'activeQuiz',
                    'activeQuiz.quiz.questions.answers',
                    'activeQuiz.quiz.localizations.questions.answers',
                    'activeSurvey',
                    'activeSurvey.survey.Questions.answers',
                    'activeSurvey.survey.localizations.Questions.answers'
                ]
            }
        });

        // Exploatation of labour
        this.resourceManager.setupResource('antiTrafficking', settings.serverBaseUri + '/api/anti-trafficking',  {
            params: {
                populate: [
                    'posts',
                    'posts.post',
                    'posts.post.content',
                    'posts.post.localizations.content',
                ]
            }
        });

        // Anti trafficking
        this.resourceManager.setupResource('eol', settings.serverBaseUri + '/api/exploitation-of-labour',  {
            params: {
                populate: [
                    'ifYouAreInForeignCountry.posts.content.links',
                    'ifYouAreInForeignCountry.posts.localizations.content.links',
                    'ifYouAreInForeignCountry.posts.thumbnail',
                    'ifYouAreInForeignCountry.posts.image',
                    'generalInformation.post.content.links',
                    'generalInformation.post.localizations.content.links',
                    'ifYouAreForeignerInSerbia.post.content.links',
                    'ifYouAreForeignerInSerbia.post.localizations.content.links',
                ]
            }
        });

    },

    /**
     * Initialize App - Read user configuration and so on
     * @returns {Promise}
     */
    async initialize() {

        this.registerResources();

        // First load config
        try {
            await this.loadConfig()
        } catch (e) {
            // Do nothing, still allow user to see app
            console.log('Failed to load configuration: ', e)
        }

        // Load resource from local database
        try {
            await this.resourceManager.loadResourcesLocally()
        } catch (e) {
            // Do nothing, still allow user to see app
            console.log('Failed to load resources locally: ', e)
        }

        if (!this.getConfig('resourcesLoadedOnce')) {

            // Load resources from remote
            try {

                await this.resourceManager.loadResourcesRemote()
                this.setConfig("resourcesLoadedOnce", true)

            } catch (e) {
                // Do nothing, still allow user to see app
                console.log('Failed to load resources remote: ', e)
            }

        } else {

            // This is not first time app is loaded, so we wont wait for data
            this.resourceManager.loadResourcesRemote()

        }


        return Promise.resolve()

    },

    /**
     *
     * @returns {Promise}
     */
    loadConfig() {

        return new Promise((resolve, reject) => {

            localforage.getItem('config').then(function (value) {

                if (value)
                    store.commit('setConfig', value)

                resolve();

            }).catch(reject)

        })

    },

    /**
     *
     * @param key
     * @param value
     */
    setConfig(key, value) {

        let cfg = store.getters.config;

        cfg[key] = value;

        store.commit('setConfig', cfg)

        this.saveConfig()

    },

    /**
     *
     * @param key
     * @returns {Promise}
     */
    getConfig(key) {

        return store.getters.config[key]

    },


    /**
     *
     */
    saveConfig() {

        return new Promise((resolve, reject) => {

            localforage.setItem('config', JSON.parse(JSON.stringify(store.getters.config)))
                .then(resolve)
                .catch(reject)

        })

    },
}