<template>

    <div class="placeholder">
        <icon class='icon' :icon="icon"></icon>
        <div class="title">{{ title }}</div>
        <p>
            <slot name="content"></slot>
        </p>
        <slot name="button"></slot>
    </div>

</template>


<script>


export default {

    props: {
        title: {
            type: String,
            required: true,
        },

        icon: {
            type: String,
            required: true,
        },

        action: {
            type: Boolean,
            default: false,
        }

    },
    data() {
        return {}
    },
    methods: {

        onClick: function () {
            this.$emit('button-click')
        },


    }

}


</script>


<style lang="scss" scoped>

@import "../scss/includes.scss";


.placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon {

        text-align: center;
        justify-content: center;
        padding-bottom: 10px;
        font-size: 50px;
    }

    .title {
        opacity: 0.9;
        font-size: 1.2em;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
    }

    p {
        font-size: 0.9em;
        text-align: center;
        opacity: 0.5;
        padding-bottom: 25px;
        line-height: 1.5em;

    }
}


</style>