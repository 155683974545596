<template>


    <div>

        <template v-for="content in post.content">

            <post-content v-if="content.__component == 'components.content-editor'" :content="markdownToHtml(content.text)" />

            <template v-if="content.__component == 'components.links'" >

                <div class="post-content">
                    <h3>{{content.title}}</h3>
                </div>
                <ul class="post-content-contact-item">
                    <li v-for="item in content.links">

                        <a :href="item.url" target="_blank">

                            <c-square-button size='small' :icon="itemIcon(item)"></c-square-button>
                            <div class="info">
                                <label>{{item.label}}</label>
                                <span>{{item.description}}</span>
                            </div>
                        </a>

                    </li>
                </ul>



            </template>

        </template>

    </div>


</template>

<script>

    export default {

        props: {
            post: {
                default: '',
            }
        },

        methods: {

            itemIcon( item ){

                if(item.icon == 'map')
                    return 'map-pin';

                return item.icon

            }


        },

    }

</script>

