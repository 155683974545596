<template>
    <background>

        <div class="flex-layout">

            <view-header
                :disable-language-picker="true"
                :disable-menu-drawer="true"
            ></view-header>

            <div class="view p-lr post-container" v-if="post">

                <h1>{{lang('menu.anti-trafficking')}}</h1>

                <post-content-strapi :post="post.attributes"></post-content-strapi>

            </div>

            <div class="view p-lr" v-else>
                <placeholder
                              icon="alert"
                              title="No post with requested parameters" >
                </placeholder>

            </div>



        </div>

    </background>
</template>

<script>

    export default {

        data(){
            return{
                post: null,
            }
        },



        beforeMount(){


            if(!this.isResourceLoaded('antiTrafficking'))
                return;

            let resource = this.getResource('antiTrafficking');

            this.post =  JSON.parse(JSON.stringify(resource.data.attributes.posts[0].post.data));

        }

    }



</script>

<style scoped>

    h1{
        font-size: 1.12em;
        line-height: 1.4em;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        margin-top: 50px;
    }

    h2{
        font-size: 0.9em;
        opacity: 0.8;
        text-align: center;
        margin-top: 3px;
        margin-bottom: 50px;
    }

    .post-container{
        padding-bottom: 30px;
    }

</style>
