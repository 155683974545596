<template>
    <background>
        <div class="flex-layout">

            <view-header :title="lang('views.anti-trafficking.title')"></view-header>
            <div class="view p-lr posts">

                <resource-placeholder :resource="wpLocalizedResourceName('posts')">

                    <div class="row" style="margin-top: 30px">

                        <div class="col col-6 col-md-6" v-for="post in posts" :key="post.id">
                            <router-link :to="wpPostResourceLink(post)">
                                <page
                                    :image-url="wordpressPostFeaturedImage(post, 'medium')"
                                    :title="limitStr(decodeHTMLEntities(post.title.rendered))"
                                >
                                </page>

                            </router-link>
                        </div>

                    </div>

                </resource-placeholder>

            </div>

        </div>

    </background>
</template>

<script>

import PageAside from "../components/PageAside";
export default {
    name: 'AntiTrafficking',
    components: {PageAside},
    computed: {

        posts() {

            return this.wpLocalizedResource('posts')

        },

    }
}


</script>

<style lang="scss" scoped>

@import "../scss/includes.scss";

.col:not(:first-child),
.col:not(:nth-child(2)) {

    margin-top: 20px;
}

.posts {
    padding-bottom: 40px;
    @include hide-during-transition();
}

</style>