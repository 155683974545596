<template>
    <background>

        <div class="flex-layout">

            <view-header :title="lang('menu.quiz')"></view-header>

            <div class="view p-lr">

                <resource-placeholder resource="quizAndSurvey">

                    <placeholder
                        icon="star"
                        :title="lang('views.quiz.title')"
                    >

                        <template v-slot:content>
                            {{ lang('views.quiz.description') }}
                        </template>

                        <template v-slot:button>
                            <c-button @click="startQuiz">
                                {{ lang('views.quiz.play') }}
                            </c-button>
                        </template>

                    </placeholder>

                </resource-placeholder>

            </div>
        </div>

    </background>

</template>

<script>

import app from '../app';

export default {
    data() {
        return {
            loading: false
        }
    },

    methods: {
        startQuiz() {
            this.$router.push('quiz');
        },

        async loadResource() {

            this.loading = true;

            await app.resourceManager.loadResourceRemote('quiz');

            this.loading = false;

        }
    }
}
</script>
