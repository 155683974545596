<template>
    <background>

        <div class="flex-layout">

            <view-header :title="lang('menu.eol')"></view-header>

            <div class="view p-lr">
                <div class="content">

                    <h1>{{ lang('views.eol.country.title') }}</h1>
                    <p class="description">{{ lang('views.eol.country.description') }}</p>

                    <router-link v-for="country in countries" :to="route(country)" :key="country.id">
                        <button class="select-country ripple">
                            <img class="flag" :src="thumbnailUrl(country)">
                            <span>{{ country.attributes.previewTitle }}</span>
                        </button>
                    </router-link>

                </div>
            </div>

        </div>

    </background>
</template>

<script>


import settings from "../settings";

export default {

    computed: {

        countries() {

            let resource = this.getResource('eol');

            return resource.data.attributes.ifYouAreInForeignCountry.posts.data;

        },

    },

    methods: {

        thumbnailUrl( country ){

            if(
                !country.attributes.thumbnail ||
                !country.attributes.thumbnail.data ||
                !country.attributes.thumbnail.data.attributes.formats
            )
                return null;



            return settings.serverBaseUri + country.attributes.thumbnail.data.attributes.formats.thumbnail.url

        },

        route( country ){

            return {
                path: '/eol-post',
                query: {
                    id: country.id
                }
            }

        }

    }

}


</script>


<style lang="scss" scoped>


@import '../scss/includes.scss';

.content {
    margin-top: 50px;
    margin-bottom: 50px;


    h1 {
        margin: 0 auto;
        display: block;
        text-align: center;
        line-height: 20px;
        font-size: 1.2em;
        font-weight: 600;

    }

    .description {
        font-size: 0.9em;
        text-align: center;
        justify-content: center;
        opacity: 0.5;
        margin-top: 20px;
        margin-bottom: 50px;
        line-height: 1.5em;


    }

    .select-country {
        @include glass-style();

        margin-bottom: 15px;
        width: 100%;
        height: $form-one-line-input-height;
        padding: 0 12px;
        border-radius: $default-border-radius-large;
        color: white;
        font-family: inherit;
        display: flex;
        align-items: center;


        .flag {

            object-fit: cover;
            object-position: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;

        }

        span {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            margin-left: 15px;


        }
    }


}


</style>