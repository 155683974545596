import langEn from '../lang/en';
import langSr from '../lang/sr';

export default {

    availableLanguages: [
       /* {
            key: 'en',
            label: 'English',
            data: langEn
        }, */{
            key: 'sr',
            label: 'Srpski',
            data: langSr
        }
    ],


}