<template>
    <background>

        <div class="flex-layout">

            <view-header :title="lang('menu.eol')"></view-header>
            <div class="view p-lr">
                <div class="content">
                    <p class="description">{{ lang('views.exploitation.description') }}</p>

                    <router-link :to="generalInformation">
                        <button class="exploitation-button ripple">
                            <icon icon="info"></icon>
                            <div class="general-info">
                                <p>{{ lang('views.exploitation.general.title') }}</p>
                                <span>{{ lang('views.exploitation.general.desc') }}</span>
                            </div>
                        </button>
                    </router-link>

                    <hr class="separator">

                    <button class="exploitation-button ripple" @click="navigate('/eol/countries')">
                        <icon icon="globe"></icon>
                        <div class="general-info">
                            <p>{{ lang('views.exploitation.domestic.title') }}</p>
                            <span>{{ lang('views.exploitation.domestic.desc') }}</span>
                        </div>
                    </button>

                    <router-link :to="ifYouAreForeignerInSerbia">
                        <button class="exploitation-button ripple">
                            <icon icon="flag"></icon>
                            <div class="general-info">
                                <p>{{ lang('views.exploitation.foreign.title') }}</p>
                                <span>{{ lang('views.exploitation.foreign.desc') }}</span>
                            </div>
                        </button>
                    </router-link>

                </div>
            </div>

        </div>

    </background>
</template>

<script>


import settings from "../settings";

export default {


    computed: {

        ifYouAreForeignerInSerbia() {

            let resource = this.getResource('eol');

            return {
                path: '/eol-post',
                query: {
                    id:  resource.data.attributes.ifYouAreForeignerInSerbia.post.data.id
                }
            }

        },

        generalInformation() {

            let resource = this.getResource('eol');

            return {
                path: '/eol-post',
                query: {
                    id:  resource.data.attributes.generalInformation.post.data.id
                }
            }

        },

    },

}


</script>


<style lang="scss" scoped>


@import '../scss/includes.scss';

.content {
    margin-top: 50px;

    .description {
        font-size: 0.9em;
        text-align: center;
        justify-content: center;
        opacity: 0.5;
        margin-top: 20px;
        margin-bottom: 50px;


    }

    .separator {
        margin-top: 35px;
        margin-bottom: 35px;

    }

    .icon {
        margin-right: 30px;
        font-size: 20px;
        margin-left: 15px;

    }

    .exploitation-button {

        @include glass-style();

        margin-bottom: 20px;
        width: 100%;
        height: 80px;
        padding: 0 12px;
        border-radius: $default-border-radius-large;
        color: white;
        font-family: inherit;
        display: flex;
        align-items: center;

        .general-info {

            display: inline-block;
            text-align: left;


            p {
                justify-content: center;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 13px;
                margin-bottom: 3px;

            }

            span {
                font-size: 0.9em;
                opacity: 0.5;

            }

        }

    }


}


</style>