import app from '../app'
import { marked } from 'marked';

export default {

    methods: {

        /**
         *
         * @param key
         * @returns {*}
         */
        isResourceLoaded(name){
            return app.resourceManager.isResourceLoaded(name) ;
        },

        /**
         *
         * @param name
         * @returns {*}
         */
        getResource(name){

            if(!app.resourceManager.getResource(name))
                return null

            return app.resourceManager.getResource(name).data;

        },

        /**
         *
         * @param resource
         * @returns {*}
         */
        strapiLocalizedResource(resource){

            let locale = app.getConfig('lang')

            if(!resource)
                return null

            if(resource.attributes.locale == locale)
                //return resource.attributes;
                return resource;

            for(let i = 0; i < resource.attributes.localizations.data.length; i ++){
                if(resource.attributes.localizations.data[i].attributes.locale == locale)
                    //return resource.attributes.localizations.data[i].attributes
                    return resource.attributes.localizations.data[i]
            }

            return null;

        },

        /**
         *
         * @param post
         * @param size
         * @returns {string|*}
         */
        wordpressPostHasFeaturedImage( post ){

            if(!post._embedded || !post._embedded['wp:featuredmedia'])
                return false;

            if(!post._embedded['wp:featuredmedia'][0])
                return false;

            return true;

        },

        /**
         *
         * @param post
         * @param size
         * @returns {string|*}
         */
        wordpressPostFeaturedImage( post, size = 'medium' ){

            let image = "";

            if(!post._embedded || !post._embedded['wp:featuredmedia'])
                return image;

            if(!post._embedded['wp:featuredmedia'][0])
                return image;

            if(!post._embedded['wp:featuredmedia'][0].media_details.sizes[size])
                size = 'medium';

            return post._embedded['wp:featuredmedia'][0].media_details.sizes[size].source_url

        },

        /**
         *
         * @param name
         * @param lang
         * @returns {string}
         */
        wpLocalizedResourceName(name, lang = null) {

            return name+':'+(lang ? lang : app.getConfig('lang'));

        },

        /**
         *
         * @param name
         * @param lang
         * @returns {*}
         */
        wpLocalizedResource(name, lang = null) {

            return this.getResource(this.wpLocalizedResourceName(name, lang));

        },


        /**
         *
         * @param markdown
         * @returns {*|string}
         */
        markdownToHtml( markdown ){

            return marked.parse(markdown)

        }

    }


}
