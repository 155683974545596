<template>
    <background>

        <div class="flex-layout">

            <view-header :title="lang('menu.quiz')"></view-header>

            <div class="timeout-notice" :class="{visible: !timeout && !end && !questionMore}">
                <icon icon="clock"></icon>
                <span>{{ lang("views.quiz.timeout") }}</span>
            </div>

            <div class="view p-lr">

                <div class="summary-placeholder" v-if="end">

                    <h1>{{ lang("views.quiz.end.title") }}</h1>
                    <p>{{ lang("views.quiz.end.description") }}</p>

                </div>

                <div class="stats">

                    <div class="stat-item number">
                        <icon icon="hash"></icon>
                        <div class="info">
                            <div class="value">
                                <span>{{ questionIndex + 1 }}</span>
                                <span class="small">/{{ questions.length }}</span>
                            </div>
                            <div class="key">{{ lang("views.quiz.stats.question") }}</div>
                        </div>
                    </div>
                    <div class="stat-item score" :class="{highlight: highlightCounts}">
                        <icon icon="star"></icon>
                        <div class="info">
                            <div class="value">
                                <span>{{ score }}</span>
                                <span class="small">/{{ totalScore }}</span>
                            </div>
                            <div class="key">{{ lang("views.quiz.stats.score") }}</div>
                        </div>
                    </div>
                    <div class="stat-item time" :class="{pulse: highlightTime}">
                        <icon icon="clock"></icon>
                        <div class="info">
                            <div class="value">
                                00:{{ timeout.toString().padStart(2, '0') }}
                            </div>
                            <div class="key">{{ lang("views.quiz.stats.time") }}</div>
                        </div>
                    </div>

                </div>


                <div class="question" v-if="currentQuestion && !end">

                    <div class="question-line">
                        <p class="question-text">
                            {{ currentQuestion.title }}
                        </p>
                        <div class="help">
                            <div class="help-item" @click="help2" :class="{active: !usedHelp2}">
                                <c-square-button size='small' icon="chevrons-right"></c-square-button>
                            </div>
                        </div>
                    </div>

                    <div class="question-more" v-if="questionMore">
                        <div class="title">
                            <icon icon="info"></icon>
                            <span>{{lang('views.quiz.info')}}</span>
                        </div>
                        <div class='desc' v-html="markdownToHtml(questionMoreDesc)"></div>
                    </div>

                    <ul class="answers" v-else>

                        <li
                            @click='markAnswer(answer)'
                            :class="answerClass(answer)"
                            class="answer ripple" v-for="(answer, index) in currentQuestion.answers"
                            :key="index">
                            <span class="index">{{ answerOptions[index] }}</span>
                            <span class="text">{{ answer.answer || "" }}</span>
                        </li>

                    </ul>

                </div>

                <div class="summary" v-if="end">


                    <div class="stats-summary">
                        <div class="stat correct">
                            <div class="count">
                                {{ userAnswersSummaryCorrect }}
                            </div>
                            <div class="desc">{{ lang("views.quiz.end.correct-answers") }}</div>
                        </div>
                        <div class="stat incorrect">
                            <div class="count">
                                {{ userAnswersSummaryIncorrect }}
                            </div>
                            <div class="desc">{{ lang("views.quiz.end.incorrect-answers") }}</div>
                        </div>
                    </div>


                    <div class="question-review">

                        <h1>{{ lang("views.quiz.end.question-summary") }}</h1>

                        <ul>
                            <li v-for="(question, index) in questions" v-bind:key="index"
                                :class="questionReviewClass(question)">

                                <div class="index">{{ index + 1 }}</div>
                                <div class="cont">
                                    <div class="text">{{ question.title }}</div>
                                    <div class="answer"
                                         v-for="answer in question.answers.filter( a => a.marked || a.correct )"
                                         :class="{
                                             correct: answer.correct,
                                             marked: answer.marked
                                         }"
                                    >
                                        <div class="indicator"></div>
                                        {{ answer.answer }}
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>

                </div>

                <div class="options" :class="{centered: previewMode}">

                    <template v-if="!end && previewMode">
                        <c-button icon="chevrons-right" icon-right='1' class="option next" @click="next">
                            {{ lang("views.quiz.action.next") }}
                        </c-button>
                    </template>
                    <template v-else-if="end">
                        <c-button icon="chevrons-left" class="option ok" @click="$router.go(-3)">
                            {{ lang("views.quiz.action.ok") }}
                        </c-button>
                    </template>
                    <template v-else>
                        <c-button icon="corner-up-right" class="option skip" @click="skip">
                            {{ lang("views.quiz.action.skip") }}
                        </c-button>

                        <c-button icon="corner-up-right" class="option confirm" :class="{disabled: !hasMarkedAnswer}"
                                  @click="confirm">
                            {{ lang("views.quiz.action.confirm") }}
                        </c-button>
                    </template>

                </div>

            </div>

        </div>
    </background>
</template>

<script>

import ProgressBar from '../components/ProgressBar.vue'
import Button from '../components/Button.vue'

import app from '../app'
import store from '../store'
import settings from "../settings";
import helpers from "../helpers";

export default {

    data() {

        return {
            answerOptions: ['А', 'B', 'C', 'D'],
            questionIndex: -1,
            questions: [],
            timeout: -1,
            previewMode: false,
            highlightCounts: false,
            highlightTime: false,
            questionEndTime: 0,
            pause: false,
            end: false,
            usedHelp1: false,
            usedHelp2: false,
            questionMore: false,
            quizId: 0,
        }

    },

    computed: {

        /**
         *
         */
        currentQuestion() {

            if (this.questionIndex < 0)
                return null;

            return this.questions[this.questionIndex]

        },

        userAnswersSummaryCorrect() {

            let value = 0;

            this.questions.forEach((q, index) => {
                q.answers.forEach((a) => {
                    if (a.marked && a.correct && (this.questionIndex > index || this.previewMode)) {
                        value++;
                    }
                })
            })

            return value

        },

        userAnswersSummaryIncorrect() {

            let value = 0;

            this.questions.forEach((q) => {
                q.answers.forEach((a) => {
                    if (a.marked && !a.correct) {
                        value++;
                    }
                })
            })

            return value

        },

        score() {

            return this.userAnswersSummaryCorrect * settings.questionPoint;

        },

        isCorrect() {

            let correct = false;

            this.currentQuestion.answers.forEach((a) => {
                if (a.marked && a.correct)
                    correct = true;
            })

            return correct;

        },

        questionMoreDesc() {

            return (this.isCorrect ? this.lang('views.quiz.right') : this.lang('views.quiz.wrong')) + this.currentQuestion.description

        },

        totalScore() {

            return this.questions.length * settings.questionPoint;

        },


        hasMarkedAnswer() {
            return this.currentQuestion ? this.currentQuestion.answers.filter(a => a.marked).length == 1 : false
        },

    },

    methods: {

        /**
         *
         * @returns {any}
         */
        prepareQuestions() {

            let quiz = this.strapiLocalizedResource(app.resourceManager.getResource('quizAndSurvey').data.data.attributes.activeQuiz.quiz.data);

            this.quizId = quiz.id;
            let questions = quiz.attributes.questions;

            questions = helpers.getRandomElementsFromArray(questions, settings.questionsInQuiz)

            // Mark correct answer
            for (let i = 0; i < questions.length; i++) {

                // Mark first as correct
                questions[i].answers[0].correct = true;

                for (let j = 0; j < questions[i].answers.length; j++) {
                    questions[i].answers[j].marked = false;
                    questions[i].answers[j].hidden = false;
                }

                // Shuffle
                questions[i].answers = helpers.shuffleArray(questions[i].answers)

            }


            this.questions = helpers.shuffleArray(questions)

        },

        /**
         *
         */
        startQuestion() {

            this.timeout = -1;
            this.previewMode = false;
            this.highlightTime = false;
            this.questionEndTime = (new Date()).getTime() + (settings.questionTimeout * 1000);

        },

        /**
         *
         */
        markAnswer(answer) {

            if (this.previewMode)
                return;

            this.currentQuestion.answers.forEach((a) => {
                a.marked = false
            })

            answer.marked = true;

        },

        /**
         *
         */
        skip() {

            this.currentQuestion.answers.forEach((a) => {
                a.marked = false
            })

            this.previewMode = true;

        },
        /**
         *
         */
        confirm() {

            if (!this.hasMarkedAnswer)
                return;

            this.previewMode = true;

        },


        /**
         *
         */
        next() {

            if (this.questionMore) {
                this.questionIndex++;

                this.questionMore = false;
                return;
            }

            if (this.questionIndex == (this.questions.length - 1)) {

                // Set user points

                if (!app.getConfig('score') || this.score > app.getConfig('score') || settings.allowResultDowngrade) {
                    app.setConfig('score', this.score)
                }

                // Here send http request
                this.sendAnswers();

                this.end = true;
                return;

            }

            this.questionMore = true;

        },

        /**
         *
         */
        help1() {

            if (this.usedHelp1 || this.previewMode)
                return

            // Exclude correct answer
            let indexes = []

            this.currentQuestion.answers.forEach((a, index) => {
                if (!a.correct)
                    indexes.push(index)
            })

            // Pick two to remove
            indexes = helpers.getRandomElementsFromArray(indexes, 2);

            indexes.forEach((i) => {

                // Do not forget do unmark
                this.currentQuestion.answers[i].marked = false;

                // Set as hidden
                this.currentQuestion.answers[i].hidden = true;
            })

            this.usedHelp1 = true


        },

        /**
         *
         */
        help2() {

            if (this.usedHelp2 || this.previewMode)
                return

            this.currentQuestion.answers.forEach((a) => {
                a.marked = a.correct;
            })

            this.previewMode = true;
            this.usedHelp2 = true


        },

        /**
         *
         */
        questionReviewClass(question) {

            let correct = false;
            let marked = false;

            question.answers.forEach((a) => {
                if (a.marked)
                    marked = true;
                if (a.marked && a.correct)
                    correct = true;
            })

            if (!marked)
                return []

            return correct ? ['correct'] : ['incorrect'];


        },


        /**
         *
         */
        answerClass(answer) {

            if (answer.hidden)
                return ['hidden']

            if (this.previewMode) {
                if (answer.marked && !answer.correct) {
                    return ['highlight-red'];
                }
                if (!answer.marked && answer.correct) {
                    return ['highlight-green'];
                }
                if (answer.marked && answer.correct) {
                    return ['highlight-green'];
                }
            } else {
                if (answer.marked) {
                    return ['highlight-yellow'];
                }
            }


        },

        async sendAnswers() {

            let answers = [];

            for (let i = 0; i < this.questions.length; i++) {

                for (let j = 0; j < this.questions[i].answers.length; j++) {
                    if ((this.questions[i].answers[j].marked === true)) {
                        answers.push({
                            id: this.questions[i].id,
                            answerId: this.questions[i].answers[j].id,
                        });
                        break;
                    }
                }
            }

            let data = {
                data: {
                    "answers": answers,
                    "quiz": this.quizId,
                }
            };

            await app.httpManager.request({
                url: 'http://localhost:1339/api/user-quiz-answers/',
                method: 'post',
                data: data,
            });

        },

    },

    watch: {

        questionIndex() {
            this.startQuestion();
        },

        score() {
            this.highlightCounts = true;
            setTimeout(() => {
                this.highlightCounts = false;
            }, 700)
        },


    },

    beforeUnmount() {

        if (this.interval)
            clearInterval(this.interval)

        if (this.listener)
            window.removeEventListener('popstate', this.listener);

    },

    created() {

        this.prepareQuestions();

        this.questionIndex = 0;

        this.interval = setInterval(() => {

            if (this.previewMode || this.end || this.pause)
                return;

            let remaining = this.questionEndTime - (new Date()).getTime();

            if (remaining <= 0) {
                this.timeout = 0;
                this.previewMode = true;
            } else {
                this.timeout = Math.floor(remaining / 1000);
            }

        }, 500);

        history.pushState(null, null, document.URL);

        let self = this;

        this.listener = () => {

            history.pushState(null, null, document.URL);

            helpers.confirmDialog({
                title: this.lang('views.quiz.exit.title'),
                message: this.lang('views.quiz.exit.desc'),
                buttons: [{
                    label: this.lang('cancel'),
                }, {
                    label: this.lang('yes'),
                    primary: true,
                    callback: () => {
                        window.removeEventListener('popstate', this.listener);
                        this.$router.go(-2)
                    }
                }]
            })

        }

        window.addEventListener('popstate', this.listener);

    }


}

</script>

<style lang="scss" scoped>
@import "../scss/includes.scss";

.view {
    display: flex;
    flex-direction: column;
}

.stats {

    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 30px;

    .stat-item {
        width: 33.333%;
        display: flex;
        justify-content: center;

        &.highlight .value span:first-child {
            animation: blinker 0.2s linear infinite;
        }

        &:not(:last-child) {
            border-right: 1px solid rgba(255, 255, 255, 0.2);
        }

        .icon {
            font-size: 20px;
            margin-top: -3px;
        }

        .info {
            width: 50px;
            margin-left: 5px;

            div:first-child {
                font-size: 1.2em;
                margin-bottom: 5px;

                .small {
                    font-size: 0.65em;
                    margin-left: 2px;
                }

            }

            div:last-child {
                opacity: 0.5;
                text-transform: uppercase;
                font-size: 0.7em;
            }

        }

    }

}

.question {
    position: relative;
    flex: 1;
}

.timeout-notice {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    background: transparentize(color(1, "dark-red"), 0.1);
    flex-direction: column;
    z-index: 1;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    transform: scale(1.05);
    justify-content: center;

    .icon {
        margin-bottom: 20px;
        font-size: 40px;
    }

    &.visible {
        pointer-events: all;
        opacity: 1;
        transform: scale(1);
    }

}

.question-text {
    font-size: 0.9em;
    line-height: 1.4em;
    height: 160px;
    font-weight: 500;
}

ul.answers {
    height: 200px;

    li {
        $height: $form-one-line-input-height;

        @include glass-style();
        height: $height;
        border-radius: $default-border-radius-small;
        display: flex;
        font-size: 0.95em;
        align-items: center;
        margin-bottom: 10px;
        transition: all 0.2s ease-in-out;
        opacity: 1;
        pointer-events: all;
        transform: translate3d(0, 0, 0);
        cursor: pointer;

        .index {
            font-weight: 700;
            width: $height;
            height: $height;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            background: rgba(255, 255, 255, 0.1);
        }

        &.hidden {
            opacity: 0;
            pointer-events: none;
            transform: translate3d(-50px, 0, 0);
        }

        &.highlight-yellow {
            @include glass-style-yellow()
        }

        &.highlight-dr {
            @include glass-style-dr()
        }

        &.highlight-red {
            @include glass-style-red()
        }

        &.highlight-green {
            @include glass-style-green()
        }


    }

}

.options {
    margin-top: 50px;
    position: relative;
    z-index: 6;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    &.centered {
        justify-content: center;
    }

}

h1 {
    font-weight: 500;
}

.summary-placeholder {
    padding-top: 70px;
    margin: 0 auto;

    h1,
    p {
        text-align: center;
    }


    p {
        margin-top: 10px;
        line-height: 1.4em;
        font-size: 0.9em;
        opacity: 0.8;
    }

}

.question-more {
    flex: 1;
    font-size: 0.9em;
    font-weight: 500;
    opacity: 0.8;

    .title {
        display: flex;
        align-items: center;
        color: white;
        text-transform: uppercase;
        margin-bottom: 15px;
        .icon{
            height: 20px;
            font-size: 20px;
            margin-right: 5px;
        }
    }

    .desc {

    }

}

.question-line {
    display: flex;
    flex-direction: row;

    .question-text {
        flex: 1;
    }

    .help {
        display: flex;
        flex-direction: row;
        margin-left: 20px;

        .help-item {
            transition: all 0.2s ease-in-out;
            opacity: 0.5;
            pointer-events: none;

            &.active {
                opacity: 1;
                pointer-events: all;
            }

        }

        .square-button {
            margin-left: 10px;
        }

    }

}

.summary {

    flex: 1;
    color: white;

    .stats-summary {

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 40px;

        .stat {
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 40px;
            text-align: center;

            &::after {

                min-width: 6px;
                min-height: 6px;
                content: "";
                border-radius: 50%;
                margin-top: 10px;
            }

            .count {
                width: 50px;
                text-align: center;
                font-size: 2em;
            }

            &.correct {

                &::after {
                    @include glass-style-green();
                    background: color(1, "green");
                }

            }

            &.incorrect {

                &::after {
                    @include glass-style-red();
                    background: color(1, "red");
                }

            }


            .desc {
                margin-top: 3px;
                width: 160px;
                opacity: 0.4;
                font-size: 0.90em;
            }

        }

    }

    .question-review {
        margin-top: 30px;

        h1 {
            text-align: center;
        }

        ul {

            margin-top: 30px;

            li {
                padding-bottom: 15px;
                margin-bottom: 15px;
                text-align: left;
                font-weight: 400;
                font-size: 0.9em;
                line-height: 1.4em;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                display: flex;


                .index {
                    width: 30px;
                    font-size: 1.1em;
                    font-weight: 500;
                }

                .text {
                    margin-bottom: 10px;
                }

                .answer {

                    display: flex;
                    align-items: center;
                    margin-bottom: 3px;

                    .indicator {
                        width: 6px;
                        height: 6px;
                        margin-right: 10px;
                        min-width: 6px;
                        border-radius: 50%;
                        background: white;
                        border: 1px solid white;
                    }

                    &.correct.marked {

                        .indicator {
                            background: color(1, "green");
                            @include glass-style-green();
                        }

                    }


                    &.marked:not(.correct) {

                        text-decoration: line-through;

                        .indicator {
                            @include glass-style-red();
                        }

                    }

                    &.incorrect {

                        .indicator {
                            background: color(1, "red");
                            @include glass-style-green();
                        }

                    }
                }


            }

        }
    }

}


</style>