export default {

    "views.language.title": "Odabir jezika",
    "views.language.confirm": "Potvrdi",

    // Intro
    "views.intro.1.title": "Dobro došli",
    "views.intro.2.title": "Borba protiv trgovine ljudima",
    "views.intro.3.title": "Saznajte više o trgovini ljudima",
    "views.intro.4.title": "Popunite anketu",
    "views.intro.1.description": "ASTRA je nevladina oganizacija posvećenja iskorenjivanju svih oblika trgovine ljudima i eksploatacije",
    "views.intro.2.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "views.intro.3.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "views.intro.4.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "views.intro.button.next": "Dalje",
    "views.intro.button.survey": "Anketa",
    "views.intro.button.survey.skip": "Preskoči",
    "views.intro.button.start.survey": "Započni anketu",
    "views.intro.button.back.survey": "Nazad",
    "views.intro.button.start.game": "Započni igru",

    // SOS
    "views.sos.description": "Ukoliko su Vam potrebne dodatne preventivne informacije, imate dileme, prepoznajete neki oblik trgovine ljudima u svom okruženju ili Vam je potrebna pomoć i podrška, možete nas kontaktirati",
    "views.sos.report-problem.title": "Prijavi problem",
    "views.sos.report-problem.message": "Kontaktirajte nas putem forme",
    "views.sos.phone.1.message": "Za žrtve trgovine ljudima",
    "views.sos.phone.2.message": "Za žrtve trgovine ljudima",
    "views.sos.fb.title": "Facebook",
    "views.sos.fb.message": "Zvanična facebook strana",
    "views.sos.mail.title": "E-Mail",
    "views.sos.mail.message": "Kontaktirajte nas putem E-mail",

    //Country residence
    "views.eol.country.title": "Odabir zemlje",
    "views.eol.country.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry",


    //Exploitation
    "views.exploitation.description":"Prikupi informacije i infromiši se o svojim pravima pre nego što doneseš odluku o radnom angažovanju.",
    "views.exploitation.general.title": "Opšte informacije",
    "views.exploitation.general.desc": "Opšte informacije o radnoj eksploataciji",
    "views.exploitation.domestic.title": "Ako ste u inostranstvu",
    "views.exploitation.domestic.desc": "Za srpske državljane",
    "views.exploitation.foreign.title": "Ako ste stranac u Srbiji",
    "views.exploitation.foreign.desc": "Za strane državljane u Srbiji",

    // Home
    "views.home.quiz.title": "Kviz igra",
    "views.home.quiz.description": "Nauči više o trgovini ljudima kroz interaktivnu igricu",
    "views.home.quiz.play": "Započni igru",
    "views.home.news-activities-title": "Vesti i aktivnosti",
    "views.home.more": "Više...",

    // Quiz
    "views.quiz.title": "Kviz",
    "views.quiz.description": "Nauči više o trgovini ljudima kroz interaktivnu igricu",
    "views.quiz.play":"Započni igru",
    'views.quiz.stats.question': 'Pitanje',
    'views.quiz.stats.score': 'Poeni',
    'views.quiz.stats.time': 'Vreme',
    'views.quiz.end.title': 'Kraj igre',
    'views.quiz.end.description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    'views.quiz.end.correct-answers': 'Tačni odgovori',
    'views.quiz.end.incorrect-answers': 'Netačni odgovori',
    'views.quiz.end.question-summary': 'Pitanja',
    'views.quiz.timeout': 'Vaše vreme je isteklo',
    'views.quiz.action.next': 'Dalje',
    'views.quiz.action.ok': 'U redu',
    'views.quiz.action.skip': 'Preskoči',
    'views.quiz.action.confirm': 'Potvrdi',
    'views.quiz.exit.title': 'Da li ste sigurni?',
    'views.quiz.exit.desc': 'Da li ste sigurni da želite da napustite igru. Vaš ostvarni rezultat će biti izgubljen',
    'views.quiz.info': 'Info',
    'views.quiz.right': 'U pravu ste. ',
    'views.quiz.wrong': 'Nažalost, pogrešili ste. ',

    // Anti trafficking
    "views.anti-trafficking.title": "Borba protiv trgovine ljudima",

    // Report problem
    "views.report-problem.intro.description": "Ukoliko se osećate nesigurno, uplašeni ste ili ne želite da delite sa nama Vaše lične podatke možete nam pisati i anonimno. ",
    "views.report-problem.form.d.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    "views.report-problem.form.a.description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    "views.report-problem.action.data": "Prijava sa ličnim podacima",
    "views.report-problem.action.anonymous": "Anonimna prijava",
    "views.report-problem.end.placeholder.title": "Hvala Vam!",
    "views.report-problem.end.placeholder.message": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "views.report-problem.end.placeholder.back": "Nazad",
    'views.report-problem.form.label.name': "Ime i prezime",
    'views.report-problem.form.label.phone': "Kontakt telefon",
    'views.report-problem.form.label.mail': "E-mail",
    'views.report-problem.form.label.title': "Naslov",
    'views.report-problem.form.label.message': "Poruka",
    'views.report-problem.form.submit': "Pošalji",
    'views.report-problem.validation.title': 'Molimo proverite polja',
    'views.report-problem.validation.desc': 'Neka polja su prazna ili nisu ispravnog formata. Molimo proverte sva polja.',
    'views.report-problem.client.title': 'Klijentska greška',
    'views.report-problem.client.desc': 'Nema mreže',
    'views.report-problem.server.title': 'Serverska greška',
    'views.report-problem.server.desc': 'Došlo je do neočekivane greške',

    // Survey
    "views.survey.title": "Anketa",
    "views.survey.description": "Kako bi proširili znanja o radnoj eksploataciji od velikog značaja bi nam bila vaša saznanja i iskustva koja možete podaliti sa nama kroz par pitanja u anonimnoj anketi",
    "views.survey.start": "Započni anketu",
    'views.survey.exit.title': 'Da li ste sigurni?',
    'views.survey.exit.desc': 'Da li ste sigurni da želite da napustite anketu?',
    "views.survey-welcome.title": "Dobro došli",
    "views.survey-welcome.placeholder.title": "Započni anketu",
    "views.survey.placeholder.content": "Maecenas sit amet sollicitudin dolor, quis fringilla tortor, quis fringilla tortor.",
    "views.survey.placeholder.button.content": "Nastavi",
    "views.survey.button.next": "Dalje",
    "views.survey.button.previous": "Nazad",
    "views.survey.end-screen.title": "Hvala Vam!",
    "views.survey.end-screen.content": "Maecenas sit amet sollicitudin dolor, quis fringilla tortor.",
    "views.survey.end-screen.button.content": "Nazad",

    // Post
    'views.post.title' : 'Vesti',

    // Resource placeholder
    'placeholder.network.title': 'Greška na mreži',
    'placeholder.network.message': 'Nema podataka. Molimo proverite vašu internet konekciju i pokušajte ponovo.',
    'placeholder.network.action': 'Pokušaj ponovo',

    // Menu
    "menu.home": "Početak",
    "menu.anti-trafficking": "Trgovina ljudima",
    "menu.eol": "Radna eksploatacija",
    "menu.sos": "SOS",
    "menu.quiz": "Kviz igra",
    "menu.survey": "Anketa",
    "menu.report-problem": "Prijavi problem",

    // Misc
    "cancel": "Odustani",
    "confirm": "Potvrdi",
    "ok": "U Redu",
    "yes": "Da",
    "no": "Ne",
    "translate": "Prevedi",
    "available-translations": "Dostupni prevodi",
    "auto-translate": "Automatski prevod",

}