<template>
    <background>
        <div class="flex-layout">
            <view-header :title="lang('menu.survey')"></view-header>

            <div class="view p-lr">

                <template v-if="showPlaceholder">

                    <placeholder
                            icon="bar-chart"
                            :title="lang('views.survey.end-screen.title')"
                    >

                        <template v-slot:content>
                            {{ lang('views.survey.end-screen.content') }}
                        </template>

                        <template v-slot:button>
                            <c-button @click="nextPage">
                                {{ lang('views.survey.end-screen.button.content') }}
                            </c-button>
                        </template>

                    </placeholder>
                </template>

                <template v-else>
                    <progress-bar :value="progressValue" :total="totalQuestions">

                    </progress-bar>

                    <div class="question">
                        <p>
                            {{ currentQuestion.title }}
                        </p>
                        <div class="answers">

                            <div v-for="answer in currentQuestion.answers"
                                 :key="answer.id"
                                 v-on:click="answerQuestion(currentQuestion.id, answer.id)"
                                 class="answer ripple"
                                 :class="{active: active === answer.id}">
                                <span class="dot"></span>
                                {{ answer.answer }}
                            </div>

                        </div>
                    </div>

                    <div class="actions" :class="{'flex-end': checkIndex(0)}">
                        <c-button icon="chevrons-left"
                                  :glass-style="false"
                                  v-on:click="previousQuestion"
                                  :class="{'display-none': checkIndex(0)}"
                        >
                            {{ lang('views.survey.button.previous') }}
                        </c-button>
                        <c-button icon="chevrons-right"
                                  :icon-right="true"
                                  v-on:click="nextQuestion"
                                  :class="{
                                    'display-none': checkIndex(totalQuestions - 1),
                                    'disabled': !hasAnsweredQuestion(currentQuestion.id)}">
                            {{ lang('views.survey.button.next') }}
                        </c-button>
                    </div>
                </template>


            </div>
        </div>

    </background>
</template>

<script>

import app from '../app';
import helpers from '../helpers'
export default {
    name: 'Survey',

    data() {
        return {
            progressValue: 0,
            totalQuestions: null,

            survey: null,
            questions: null,

            currentQuestion: {
                id: null,
                question_title: null,
                answers: [{
                    id: null,
                    answer: null
                }]
            },
            answeredQuestions: [],
            currentIndex: 0,

            showPlaceholder: false,
            surveyId: 0,
        }
    },

    methods: {
        nextQuestion() {

            this.setQurrentQuestion(++this.currentIndex);
            this.progressValue++;
        },

        previousQuestion() {

            this.setQurrentQuestion(--this.currentIndex);
            this.progressValue--;
        },

        answerQuestion(currentQuestionId, answerId) {


            let elementInArray = this.indexOfAnsweredQueston(currentQuestionId);

            if (elementInArray === -1) {
                this.answeredQuestions.push(this.setAnswerQuestionObject(currentQuestionId, answerId));

            } else {
                this.answeredQuestions.splice(elementInArray, 1, this.setAnswerQuestionObject(currentQuestionId, answerId));
            }

            if (this.checkIndex(this.totalQuestions - 1))
                return this.sendAnswers();

        },

        setAnswerQuestionObject(currentQuestionId, answerId) {
            return {
                id: currentQuestionId,
                answerId: answerId,
            }
        },

        indexOfAnsweredQueston(currentQuestionId) {
            return this.answeredQuestions.findIndex(element => element.id === currentQuestionId);
        },

        hasAnsweredQuestion(currentQuestionId) {
            let index = this.indexOfAnsweredQueston(currentQuestionId);
            if (index === -1)
                return false;
            return true
        },

        setQurrentQuestion(currentIndex) {
            this.currentQuestion = this.questions[currentIndex];
        },

        checkIndex(index) {
            return this.currentIndex === index;
        },

        async sendAnswers() {

                this.showPlaceholder = true;

                let data = {
                    data: {
                        "answers" : JSON.parse(JSON.stringify(this.answeredQuestions)),
                        "survey": this.surveyId,
                    }
                };

                await app.httpManager.request({
                    url: 'http://localhost:1339/api/user-survey-answers/',
                    method: 'post',
                    data: data,
                });

        },

        nextPage() {

            window.removeEventListener('popstate', this.listener);

            this.$router.go(-3)

        },
    },

    computed: {
        active() {
            let element = this.answeredQuestions.find(element => element.id === this.currentQuestion.id);
            if (element !== undefined)
                return element.answerId;
            return -1;
        }
    },

    beforeUnmount() {

        if (this.listener)
            window.removeEventListener('popstate', this.listener);

    },
    created() {

        let survey = this.strapiLocalizedResource(app.resourceManager.getResource('quizAndSurvey').data.data.attributes.activeSurvey.survey.data);

        this.questions = survey.attributes.Questions;
        this.surveyId = survey.id;

        //app.resourceManager.getResource('quizAndSurvey').data.data.id;

        this.totalQuestions = this.questions.length;
        this.currentQuestion = this.questions[0];

        history.pushState(null, null, document.URL);

        this.listener = () => {

            history.pushState(null, null, document.URL);

            helpers.confirmDialog({
                title: this.lang('views.survey.exit.title'),
                message: this.lang('views.survey.exit.desc'),
                buttons: [{
                    label: this.lang('cancel'),
                }, {
                    label: this.lang('yes'),
                    primary: true,
                    callback: () => {
                        window.removeEventListener('popstate', this.listener);
                        this.$router.go(-2)
                    }
                }]
            })

        }

        window.addEventListener('popstate', this.listener);

    },


}

</script>

<style lang="scss" scoped>
@import "../scss/includes.scss";

.display-none {
    display: none;
}

.separator {
    margin-top: 30px;
    margin-bottom: 40px;
}

.view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 40px;
}

.question {
    p {
        font-size: 1.0em;
        line-height: 1.4em;
        height: 100px;
    }

    .answers {
        .answer {
            @include glass-style();

            display: flex;
            align-items: center;
            border-radius: $default-border-radius-large;
            padding: 0px 20px;
            cursor: pointer;

            height: $form-one-line-input-height;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &.active {
                @include glass-style-yellow();

                .dot {
                    background-color: rgba(255, 255, 255, 1);
                }
            }

            .dot {
                width: 7px;
                height: 7px;
                border-radius: 100%;
                display: block;
                background-color: rgba(255, 255, 255, 0.6);
                margin-right: 20px;

            }
        }
    }
}

.actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    &.flex-end {
        justify-content: flex-end;
    }
}
</style>