<template>
    <div class="article">
        <div class="image" :style="{backgroundImage: 'url(' + imageUrl + ')'}">
        </div>

        <div class="text-container ripple">
            <span>{{meta}}</span>
            <p>{{ title }}</p>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            title: {
                type: String,
                required: true,
            },
            imageUrl: {
                type: String,
                required: true,
            },
            meta: {
                type: String,
                required: true,
            },
        }
    }


</script>

<style lang="scss" scoped>

    @import "../scss/includes.scss";

    .article{
        @include glass-style();

        border-radius: $default-border-radius-large;
        width: 100%;
        overflow: hidden;

        .image{

            width: 100%;
            height: 150px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .text-container{

            display: flex;
            flex-direction: column;
            padding: 20px 15px;

            p{
                text-align: left;
                font-size: 1.0em;
                line-height: 1.2em;
                font-weight: 400;
            }
            span{

                font-size: 0.8em;
                margin-bottom: 10px;
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }


</style>