<template>

    <div class="confirm-dialog" :class="{visible}">

        <div class="title">{{title}}</div>

        <p class="message">{{message}}</p>

        <div class="buttons">

            <button v-for="button in buttons" class="ripple" :class="{primary: button.primary}" @click="handleButtonClick(button)">
                {{button.label}}
            </button>

        </div>

    </div>

</template>

<script>

    export default {

        data() {
            return {
                visible: false,
            }
        },

        props: {

            title: {
                required: true,
            },

            message: {
                required: true,
            },

            buttons: {
                required: false,
            }

        },

        methods: {

            handleButtonClick(button){

                if(button.callback)
                    button.callback()

                this.$emit("end")

            }

        },

        mounted(){

            setTimeout( () => {
                this.visible = true
            }, 100 )

        }

    }

</script>

<style lang="scss" scoped>

    @import "../scss/includes.scss";

    .confirm-dialog{


        color: black;
        width: 310px;
        border-radius: 15px;
        box-shadow: 0 0 20px rgba(0,0,0,0.7);
        display: flex;
        flex-direction:column;
        overflow: hidden;
        opacity: 0;
        transition: all 0.4s ease-in-out;


        &.visible{
            opacity: 1;
        }

        .title{
            font-size: 1.2em;
            padding: 20px;
            font-weight: 500;
            background: url('../assets/img/background-dialog.png');
            background-size: cover;
            color: white;
        }

        p{
            padding: 20px;
            line-height: 1.4em;
            flex: 1;
            background: white;
        }

        .buttons{
            border-top: 1px solid #efefef;
            padding: 20px;
            display: flex;
            justify-content: flex-end;
            background: white;
        }

        button{
            background: transparent;
            color: black;
            height: 30px;
            padding: 0 20px;
            font-family: inherit;
            text-transform: uppercase;
            font-weight: 600;
            border-radius: $default-border-radius-small;
            border: none;
            margin-left: 10px;

            &.primary{
                color: white;
                background: #822202;
            }
        }

    }

</style>












