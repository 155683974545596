<template>
    <div class="drawer-menu-container" :class="{visible: $store.state.menuVisible}">
        <div class="overlay" @click="$store.commit('setMenuVisible', false)"></div>
        <div class="menu">
            <div class="scroll">

                <div class="content">
                    <div class="top-menu-right-btn">
                        <menu-button></menu-button>
                    </div>

                    <img src="../assets/img/logo.svg" alt="">

                    <ul>
                        <li @click="goTo('/')">
                            <div class="icon">
                                <icon icon="home"></icon>
                            </div>
                            <span>{{lang("menu.home")}}</span>
                        </li>
                        <li @click="goTo('/anti-trafficking')">
                            <div class="icon">
                                <icon icon="astra"></icon>
                            </div>
                            <span>{{lang("menu.anti-trafficking")}}</span>
                        </li>
                        <li @click="goTo('/eol')">
                            <div class="icon">
                                <icon icon="users"></icon>
                            </div>
                            <span>{{lang("menu.eol")}}</span>
                        </li>
                        <li @click="goTo('/sos')">
                            <div class="icon">
                                <icon icon="phone"></icon>
                            </div>
                            <span>{{lang("menu.sos")}}</span>
                        </li>
                        <li @click="goTo('/quiz')">
                            <div class="icon">
                                <icon icon="star"></icon>
                            </div>
                            <span>{{lang("menu.quiz")}}</span>
                        </li>
                        <li @click="goTo('/survey-welcome')">
                            <div class="icon">
                                <icon icon="edit"></icon>
                            </div>
                            <span>{{lang("menu.survey")}}</span>
                        </li>
                        <li @click="goTo('/report')">
                            <div class="icon">
                                <icon icon="send"></icon>
                            </div>
                            <span>{{lang("menu.report-problem")}}</span>
                        </li>
                    </ul>

                    <span class="website">www.astra.rs</span>
                </div>


            </div>
        </div>
    </div>
</template>

<script>

    export default {
        methods: {

            /**
             *
             * @param page
             */
            goTo(page){



                this.$store.commit('setMenuVisible', false)

                setTimeout( () => {
                    this.navigate(page);
                }, 200 )

            }

        }
    }

</script>

<style lang="scss" scoped>

    @import "../scss/includes.scss";

    .drawer-menu-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        pointer-events: none;

        &.visible {

            pointer-events: all;

            .overlay {
                opacity: 1;
            }

            .menu {
                transform: translate3d(0px, 0, 0);
                opacity: 1;
            }

        }

    }

    .menu {
        width: 70%;
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        background: url('../assets/img/background-blurred.png');
        background-size: cover;
        background-position: center top;
        transform: translate3d(50px, 0, 0);
        opacity: 0;
        transition: transform 0.3s ease-in-out, opacity 0.2s cubic-bezier(0.65, 0, 0.35, 1);

        .scroll {
            position: relative;
            overflow-y: auto;
            height: 100%;

            .content {
                position: relative;
                min-height: 530px;
                height: 100%;
            }
        }

    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    img {
        width: 120px;
        display: inline-block;
        margin-left: $default-page-padding;
        margin-top: $default-page-padding;
        margin-bottom: 70px;
    }

    ul {
        width: 100%;

        li {
            @extend %ripple;
            display: flex;
            align-items: center;
            padding: 0 $default-page-padding;
            height: 45px;
            cursor: pointer;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }

            .icon {
                color: white;
                font-size: 20px;
                margin-right: 5px;
                width: 24px;
            }

            span {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
            }

        }
    }

    .website {
        position: absolute;
        bottom: $default-page-padding - 15px;
        width: 100%;
        text-align: center;
        padding: 15px 0;
        font-size: 0.85em;
        opacity: 0.7;

    }


</style>