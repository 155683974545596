<template>
    <div v-if="feather" class="icon" v-html="iconHtml" ref="icon" >
    </div>
    <template v-else>
        <AstraIcon v-if="icon == 'astra'" ref="icon" class='icon' style="margin-left: -0.25em;"></AstraIcon>
        <!-- Other custom icons -->
    </template>
</template>

<script>

    import feather from 'feather-icons'

    import AstraIcon from '../assets/img/logo-icon.svg?inline'

    export default {

        components: {
            AstraIcon
        },

        props: {
            icon: {
                required: true,
                type: String
            }
        },

        data() {
            return {
                iconHtml: null,
            }
        },

        computed: {
            feather(){
                return feather.icons[this.icon]
            }
        },

        mounted(){

            if(this.feather){

                this.iconHtml = feather.icons[this.icon].toSvg()

                this.$nextTick(() => {
                    this.$refs.icon.querySelector("svg").style.width = "1em"
                    this.$refs.icon.querySelector("svg").style.height = "1em"
                    this.$refs.icon.querySelector("svg").style.minWidth = "1em"
                })

            }

        }

    }


</script>

<style lang="scss" scoped>

    .icon {
        width: 1em;
        height: 1em;
    }

</style>