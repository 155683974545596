import {createRouter, createWebHistory} from 'vue-router'

import Home from '../views/Home.vue'
import WelcomePage from '../views/WelcomePage.vue'
import Language from '../views/Language.vue'
import AntiTrafficking from '../views/AntiTrafficking.vue'
import Survey from '../views/Survey.vue'
import SurveyWelcome from '../views/SurveyWelcome.vue'
import ReportProblemForm from '../views/ReportProblemForm.vue'
import Sos from '../views/Sos.vue'
import ReportProblem from '../views/ReportProblem.vue'
import Post from '../views/Post.vue'
import Quiz from '../views/Quiz.vue'
import QuizWelcome from "../views/QuizWelcome";
import EOLCountries from '../views/EOLCountries.vue'
import EOL from '../views/EOL.vue'
import app from "../app";
import EOLPost from "../views/EOLPost";
import ATPost from "../views/ATPost";

const routes = [
    {
        path: '/',
        component: Home,
        meta: {
            depth: 10
        },
    },
    {
        path: '/language',
        component: Language,
        meta: {
            depth: 0
        },
    },
    {
        path: '/welcome',
        component: WelcomePage,
        meta: {
            depth: 0
        },
    },
    {
        path: '/anti-trafficking',
        component: ATPost,
        meta: {
            depth: 20
        },
    },
    {
        path: '/survey',
        component: Survey,
        meta: {
            depth: 20
        },
    },
    {
        path: '/quiz-welcome',
        component: QuizWelcome,
        meta: {
            depth: 20
        },
    },
    {
        path: '/quiz',
        component: Quiz,
        meta: {
            depth: 21
        },
    },
    {
        path: '/sos',
        component: Sos,
        meta: {
            depth: 20
        },
    },
    {
        path: '/report',
        component: ReportProblem,
        meta: {
            depth: 30
        },
    },
    {
        path: '/report/form',
        component: ReportProblemForm,
        meta: {
            depth: 31
        },
    },
    {
        path: '/post',
        component: Post,
        meta: {
            depth: 10
        },
    },
    {
        path: '/eol-post',
        component: EOLPost,
        meta: {
            depth: 10
        },
    },
    {
        path: '/at-post',
        component: ATPost,
        meta: {
            depth: 10
        },
    },
    {
        path: '/survey-welcome',
        component: SurveyWelcome,
        meta: {
            depth: 20
        },
    },
    {
        path: '/eol',
        component: EOL,
        meta: {
            depth: 30
        },
    },
    {
        path: '/eol/countries',
        component: EOLCountries,
        meta: {
            depth: 31
        },
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from) => {



})

router.afterEach((to, from) => {

    to.meta.transitionName = to.meta.depth < from.meta.depth ? 'slide-down' : 'slide-up'

})

export default router
