<template>
    <top-bar>
        <div class="view p-lr p-tb">

                <div class="top">

                    <div class="top-menu-left-btn">
                        <language-picker :disabled="disableLanguagePicker"></language-picker>
                    </div>

                    <div class="top-menu-right-btn">
                        <menu-button :disabled="disableMenuDrawer"></menu-button>
                    </div>

                </div>

                <hr class="separator">

                <div class="title">
                    <button class="ripple" @click="goBack">
                        <icon icon="chevron-left"></icon>
                    </button>
                    <span>{{title}}</span>
                </div>
            </div>

    </top-bar>
</template>

<script>

    export default {

        props: {
            title: {
                type: String,
                required: false,
            },
            action: {
                type: Function,
            },
            disableLanguagePicker: {
                type: Boolean,
                default: false,
            },
            disableMenuDrawer: {
                type: Boolean,
                default: false,
            }
        },

        methods: {
            goBack(){
                return this.action? this.action() : this.$router.back();
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import "../scss/includes.scss";

    .top-bar{
        height: 140px;
        z-index: 10;
    }

    .top{
        height: 40px;
    }

    .title{
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        position: relative;
        margin-bottom: -15px;

        span{
            font-size: 16px;
            text-transform: uppercase;
        }

        button{
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            background: none;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline: none;
            color: white;
            font-size: 30px;
        }
    }

</style>