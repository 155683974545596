export default {

    // Quiz
    questionPoint: 5,

    // Sec
    questionTimeout: 15,

    // Questions in Quiz
    questionsInQuiz: 15,

    // If user won less points than previous time should we downgrade points
    allowResultDowngrade: true,

    // Pages
    newsPageUrl: 'https://www.astra.rs/category/astra_vesti/',

    fbPageUrl: 'https://www.facebook.com/astraantitrafficking/',
    ytPageUrl: 'https://www.youtube.com/user/AstraAntiTrafficking',
    instaPageUrl: 'https://www.instagram.com/astra_antitrafficking',

    serverBaseUri: "https://astra-api.modem.rs",

}
