<template>
    <background>

        <div class="flex-layout">

            <div class="view p-lr">

                <h2 class="default">{{lang('views.language.title')}}</h2>

                <c-select icon="globe" :options="languages" v-model="language"></c-select>

                <c-button @click="confirm">{{lang('views.language.confirm')}}</c-button>

            </div>

        </div>

    </background>
</template>

<script>

    import lang from '../lang'
    import app from '../app'

    export default {

        data() {
            return {
                language: app.getConfig('lang'),
                languages: lang.availableLanguages.map((l) => {
                    return {
                        key: l.key,
                        label: l.label
                    }
                })
            }
        },

        watch: {
            language(newValue, oldValue){

                if(!oldValue)
                    return;

                app.setConfig('lang', newValue)

            }
        },

        methods: {

            confirm(){

                if(this.$route.query.redirect){
                    this.$router.push({
                        path: this.$route.query.redirect
                    })
                    return;
                }

                this.$router.back()

            }

        },


    }

</script>


<style scoped lang="scss">

    .view {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        height: 100%;
    }

    .button{
        margin-top: 20px;
    }

</style>