<template>
    <background>
        <div class="flex-layout">
            <view-header :title="lang('menu.report-problem')"></view-header>
            <div class="view p-lr p-tb" v-if="!submitted">
                <p class="text-container" v-if="anonymous">{{lang('views.report-problem.form.a.description')}}</p>
                <p class="text-container" v-else>{{lang('views.report-problem.form.d.description')}}</p>

                <form @submit.prevent="submitData">

                    <template v-if="!anonymous">
                        <div class="input">
                            <label for="name">{{ lang('views.report-problem.form.label.name') }}</label>
                            <input type="text" id="name" name="name" v-model="name">
                        </div>
                        <div class="input">
                            <label for="cp">{{ lang('views.report-problem.form.label.phone') }}</label>
                            <input type="text" id="cp" name="cp" v-model="phone">
                        </div>
                        <div class="input">
                            <label for="cm">{{ lang('views.report-problem.form.label.mail') }}</label>
                            <input type="text" id="cm" name="cm" v-model="mail">
                        </div>

                        <hr class="separator">
                    </template>

                    <div class="input">
                        <label class="form-label">{{ lang('views.report-problem.form.label.message') }}</label>
                        <textarea class="form-control" v-model="message" ></textarea>
                    </div>

                    <div class="button-container">
                        <c-button :loading="loading">
                            {{ lang('views.report-problem.form.submit') }}
                        </c-button>
                    </div>
                </form>
            </div>

            <div class="view p-lr p-tb" v-else>

                <placeholder
                    icon="check"
                    :title="lang('views.report-problem.end.placeholder.title')"
                    :action="true"
                    @button-click="$router.back()">
                    <template v-slot:content>
                        {{ lang('views.report-problem.end.placeholder.message') }}
                    </template>
                    <template v-slot:button-content>
                        {{lang('views.report-problem.end.placeholder.back')}}
                    </template>
                </placeholder>

            </div>

        </div>
    </background>
</template>

<script>
    import app from '../app';
    import axios from 'axios';
    import helpers from '../helpers';
    import Joi from 'joi';

    export default{
        data(){
            return {
                loading: false,
                submitted: false,

                name: null,
                phone: null,
                mail: null,
                message: null,

            }
        },

        computed: {

            anonymous(){
                return this.$route.query.anonymous
            }

        },

        methods: {
            validate(){

                let rules = {
                    message: Joi.string().required(),
                };

                let inputs = {
                    message: this.message
                };

                if (!this.anonymous) {
                    rules = {
                        ...rules, ... {
                            name: Joi.string().required(),
                            phone: Joi.string().required(),
                            mail: Joi.string().email({tlds: false}).required(),

                        }
                    };

                    inputs = {
                        ...inputs, ...{
                            name: this.name,
                            phone: this.phone,
                            mail: this.mail,
                        }
                    }

                }

                const schema = Joi.object(rules);

                const {error, value} = schema.validate(inputs);

                if (error) {
                    return null;
                }

                return value;
            },
            async submitData(){
                let validatedData;

                if (!(validatedData = this.validate())) {

                    this.showModal(this.lang('views.report-problem.validation.title'), this.lang('views.report-problem.validation.desc'));
                    return;
                }


                try {
                    this.loading = true;
                    await axios.post('http://192.168.0.102:1339/api/reported-problems/', {data: validatedData});
                    this.submitted = true;

                } catch (error) {

                    if (error.response) {

                        this.showModal(this.lang('views.report-problem.server.title'), this.lang('views.report-problem.server.desc'));
                    }

                    if (error.request) {

                        if (window.navigator.onLine) {

                            this.showModal(this.lang('views.report-problem.server.title'), this.lang('views.report-problem.server.desc'));
                        } else {

                            this.showModal(this.lang('views.report-problem.client.title'), this.lang('views.report-problem.client.desc'));
                        }
                    }

                } finally {
                    this.loading = false;
                }
            },
            showModal(title, message){
                helpers.confirmDialog({
                    title: title,
                    message: message,
                    buttons: [{
                        label: this.lang('ok'),
                    },]
                });
            }
        }
    }

</script>

<style lang="scss" scoped>
    @import "../scss/includes.scss";

    form{
        margin-top: 20px;
    }

    .input{
        margin-bottom: 15px;
        input, textarea{
            @include one-line-form-element-glass();
            font-size: 1.1em;
            box-sizing: border-box;

        }
        label{
            display: block;
            text-transform: uppercase;
            padding-left: 15px;
            margin-bottom: 5px;
            font-size: 0.8em;
            font-weight: 600;
        }

        textarea{
            min-height: 150px;
        }
    }
    .separator{
        margin-top: 35px;
        margin-bottom: 20px;
    }
    .button-container{
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }

</style>